import axios from "axios";

export const listAll = parkingLotId => {
  return axios.get("/parking/monthlyRentalPackage/listAll?parkingLotId=" + parkingLotId);
};

export const list = (params, page, pageSize) => {
  return axios.get("/parking/monthlyRentalPackage/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/parking/monthlyRentalPackage/add", vo);
};

export const detail = id => {
  return axios.get(`/parking/monthlyRentalPackage/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/parking/monthlyRentalPackage/${id}/edit`, vo);
};

export const deleteMonthlyRentalPackage = id => {
  return axios.delete(`/parking/monthlyRentalPackage/${id}/del`);
};
